import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePnMain(props) {
  return (
    <div className="pnBody">
      <Helmet>
        <title>sf2Pro - Deliver Services Professionally and Efficiently</title>
        <meta
          name="description"
          content="sf2Pro framework is designed specially for service domains regarding Project and Non-project businesses; sf2Pro's target users include Parties involved in service contracts and Service teams within organizations"
        />
        <meta
          name="keywords"
          content="sf2Pro,Demand-Supply Collaboration,Professional Service,Management by Objectives,Yige"
        />
      </Helmet>
      <div className="pnWrapperColumn">
        <h3 className="secTitlePn">sf2Pro</h3>

        <p className="secDescriptionPn">Demand-Supply Collaboration Platform for Professionals</p>
        <br />

        <button id="buttonFreeTrial" onClick={() => props.goResource()}>
          Start Trial
        </button>

        <br />

        <a
          style={{ textDecoration: "none" }}
          href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfIntroductionEN.mp4"}
          target={"_blank"}
          rel="noreferrer"
        >
          <button id="buttonLight">Overview</button>
        </a>

        <img className="pnImageInSection" src={require("../assets/picMainA1.png")} alt="sf2Pro"></img>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            <span className="secTitleSpn">Walk with songs</span> sf2Pro accompanies you to compose your songs
          </p>
        </div>

        <div className="pnDescMain">
          <p className="secDescriptionPn">
            <span className="secTitleSpn">Dance with tutors</span> sf2Pro helps you find true mentors and friends,
            witnessing your growth
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <h3 className="secTitlePn">Deliver Professionally and Efficiently</h3>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            <span className="secTitleSpn">Collaborate on Agreements </span>
            Based on service objects, sf2Pro encourages Demand and Supply parties to log business decisions and provides
            sufficient functionalities for teams to track subsequent implementations
          </p>
        </div>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            <span className="secTitleSpn">Succeed on Objectives </span>
            In service delivery processes, sf2Pro promotes a cycle of objective-action-feedback in order to guide teams
            to become more agile and result-oriented
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">Facilitate Steady Service Deliveries</h3>
          <p className="secSubTitleSpn">Help teams build trust in natural ways</p>
        </div>
        <img className="pnImageInSection" src={require("../assets/picMain31.png")} alt="sf2Pro"></img>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            Service objects in sf2Pro are what practitioners have been familiar with:
            <span className="secTitleSpn">Requirement, Solution, Task, Issue and Risk</span>
            <br />
            <br />
            sf2Pro helps teams refine relationships among service objects and alert relevant stakeholders any serious
            issues or risks.
            <br />
            <br />
            Meanwhile, sf2Pro applies collaboration and evaluation mechanisms to deliverables across the whole service
            object network.
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">Improve Performance by Professionalism</h3>
          <p className="secSubTitleSpn">Guide team collaboration towards harmony</p>
        </div>
        <img className="pnImageInSectionS" src={require("../assets/picMain32.png")} alt="sf2Pro"></img>
        <div className="pnDescMain">
          <h3 className="secTitlePn">Functional Structure</h3>
          <br />
          <p className="secDescriptionPn">
            sf2Pro framework is tailored for service domains which include:
            <br />
            <br />
            Project businesses
            <br />
            Non-project businesses
            <br />
            <br />
            <span className="secTitleSpn">sf2Pro's target users</span> are:
            <br />
            <br />
            Parties involved in service contracts
            <br />
            Service teams within organizations
            <br />
            <br />
            <span className="secTitleSpn">Functional elements </span>include:
            <br />
            <br />
            Service object maintenance <br />
            Consensus among stakeholders <br />
            Evaluation on service deliverables <br />
            Association among service objects <br />
            Analysis and alert based on measures <br />
            Full version management <br />
            Studio and team management <br />
            System message and notice
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">Advanced know-how about Service Industries</h3>
        </div>
        <img className="pnImageInSectionS" src={require("../assets/picMain41.png")} alt="sf2Pro"></img>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            <span className="secTitleSpn">sf2Pro team </span>
            consists of very experienced designers and architects who have served Fortune 500 clients for more than 20
            years.
            <br /> <br />
            sf2Pro team has in-depth knowledge of service team management and desires to help service professionals to
            operate their businesses on a digital platform which is tailored for their needs.
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">Vision</h3>
          <p className="secSubTitleSpn">
            sf2Pro's vision is to help Demand and Supply parties to build a team in which all members can work together in harmony, and happily build a team culture as follows:
          </p>
        </div>
        <img className="pnImageInSection" src={require("../assets/picMain5EN.png")} alt="sf2Pro"></img>
        <div className="pnMainDivColumnBlue">
          <p></p>
          <h3 className="secTitleWhitePn">Demand-Supply Collaboration Platform</h3>
          <p className="secDescriptionWhitePn">Register and get a welcome gift!</p>
          <button onClick={() => props.goResource()}>Start Trial</button>
          <p></p>
        </div>
      </div>
    </div>
  );
}
