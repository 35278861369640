export const faq = [
  {
    q: "项日葵是像飞书和钉钉一样的团队协同工具吗？",
    a: "项日葵聚焦服务领域。除了改善团队协同外，还提供多种功能处理服务领域中的业务难点（比如缓解服务供需方的矛盾、避免成员间的不信任以及减少沟通歧义等）。",
  },
  {
    q: "项日葵仅适用于服务合同中的甲乙方吗？",
    a: "项日葵不仅适用于服务合同中的甲乙方，也适用于企业中的共享职能部门。如果把企业中的合作看成是内部甲乙方合作的话，项日葵就足以派上用场。",
  },
  {
    q: "项日葵提供定制化服务吗?",
    a: "项日葵目前仅提供SaaS服务，也就是订阅服务。项日葵为VIP工作室提供一定程度的定制化帮助（比如建立自定义指标目标等）。但在短时间内，项日葵暂不支持大规模定制和本地化安装。",
  },
  {
    q: "项日葵按人天价格收费，是不是说（比如）买了300人天就可以用300天？",
    a: "取决于工作室中的成员数：如果工作室有两个成员，300人天最多可以用150天；如果有三个成员，300人天最多可以用100天；另外，用户注册工作室时，项日葵会赠送一定数量的短信和存储额度，如果用户实际使用超过了这个额度，项日葵会折算扣除可用余额中的等价人天（请参见价格说明中的扣费规则）。",
  },
  {
    q: "项日葵有英文版本吗？可以在海外使用吗？",
    a: "项日葵支持英文登录，但目前注册用的手机号必须是中国大陆的手机号。现在以至未来一段时间，项日葵的运营都是以国家为单位的：项日葵将会尽快推出以国家为单位的海外版本。",
  },
  {
    q: "项日葵的工作室可以永久使用下去吗？",
    a: "只要正常续费，项日葵的工作室是可以一直使用的。如果用户停止续费，项日葵会向用户提供一年的只读访问权限。一年过后，项日葵会将工作室的数据转为归档状态。归档状态三年后（如果用户无意续费），相关数据将被物理删除。",
  },
  {
    q: "用户数据放在项日葵可靠吗？会不会泄漏出去或与其他用户的数据混在一起？",
    a: "从产品的设计角度，项日葵工作室的数据是彼此隔离的，用户没有必要担心数据会混淆。除非用户授权，每个工作室的数据只有用户自己能访问。《项日葵隐私保护协议》也解释了这样的SaaS系统能达到的安全级别。另，项日葵的系统硬件，均来自业界头牌的云服务商，项日葵的硬件和网络安全因此有专业的品质保障。",
  },
];
