export const faq = [
  {
    q: "Is sf2Pro similar to DingTalk and Feishu?",
    a: "sf2Pro focuses on Service industries. Besides facilitating collaboration, sf2Pro provides various functions to deal with service pain points (e.g. lessen misunderstandings or avoid Demand-Supply conflicts).",
  },
  {
    q: "Is sf2Pro only applicable to contracting parties?",
    a: "No. sf2Pro also serves teams within an organization, such as shared service teams. Internal service teams could be treated as internal Supply parties.",
  },
  {
    q: "Does sf2Pro provide custom services?",
    a: "sf2Pro provides flexible configs for studio Admins to manage their custom requirements. Besides, extra services are available for VIP studios, e.g., to define custom objectives and measures. However, sf2Pro has no plan to provide custom developments on specific studios.",
  },
  {
    q: "Is it true that if an Admin has paid for 300 man-days, their studio will be entitled to run for 300 days?",
    a: "It depends on the number of users in the studio. For example, if there are 2 users in the studio concerned, the studio balance will run up in 150 days; if there are 3 users, the studio balance will run up in 100 days. In addition, sf2Pro offers some promotional benefits from time to time, which may increase the available man-days. For more details, please refer to sf2Pro Pricing.",
  },
  {
    q: "Does sf2Pro provide an English version? Could it be used abroad?",
    a: "sf2Pro supports English layouts. Yet the user registration requires a mobile number in mainland China. sf2Pro services are country based and an international version will be released in the near future.",
  },
  {
    q: "Could a sf2Pro studio be used for ever?",
    a: "A sf2Pro studio is always available before the studio balance is consumed up. If Admin hasn't renewed a studio for a long time, sf2Pro will suspend operation or restrict access to that studio. If this situation continues for a year, sf2Pro will archive the studio data and the data will be deleted physically three years later.",
  },
  {
    q: "Are studio data safe in sf2Pro, without any risks of leakage or mixup",
    a: "sf2Pro isolates data physically from a design point of perspective. Users don't have to worry the studio data are mixed up or accessed by other studio users. Users may check more details in the document sf2Pro Privacy Protection Agreement. Furthermore, sf2Pro services are hosted on hardware of the well-known cloud providers which are supposed to comply with the highest data security standards.",
  },
];
