import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";
import { faq } from "../context/Constant";

export default function SubPagePnResource(props) {
  const [searchKey, setSearchKey] = useState("");
  const [showList, setShowList] = useState(faq);
  const [foundAny, setFoundAny] = useState(true);

  const clearSearch = () => {
    setSearchKey((searchKey) => "");
    setFoundAny(true);
    setShowList(faq);
  };

  const startSearch = () => {
    const newItems = showList.filter((el) => el.q.includes(searchKey) || el.a.includes(searchKey));
    if (newItems.length > 0) {
      setShowList(newItems);
      setFoundAny(true);
    } else {
      setShowList([]);
      setSearchKey("");
      setFoundAny(false);
    }
  };

  return (
    <div className="pnBody">
      <Helmet>
        <title>新用户注册工作室即可获得90人天的免费试用额度</title>
        <meta
          name="description"
          content="移动端注册请先下载移动端APP；项日葵用户可在系统内直接搜索帮助文件；项日葵会定期向用户发布更新的培训材料；如在操作上遇到困难，用户可在系统内创建“用户反馈”（客服会尽快联系您）"
        />
        <meta name="keywords" content="项日葵,服务管理,目标管理,屹歌" />
      </Helmet>
      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">项日葵注册和下载</h3>
          <p className="secDescriptionPn" style={{ marginBottom: 16 }}>
            <br />
            <span className="secTitleSpn">新用户注册</span>
            工作室即可获得90人天的免费试用额度，点击以下按钮下载APP
            <br />
            <br />
          </p>
          <a
            style={{ alignSelf: "center" }}
            href={
              "https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/apk/sf2Pro_SunflowerToProfessionals_latest.apk"
            }
            target={"_blank"}
            rel="noreferrer"
            id="userDownloadS5android"
            download
          >
            安卓手机客户端
          </a>
          <a
            style={{ alignSelf: "center" }}
            href="https://apps.apple.com/cn/app/%E9%A1%B9%E6%97%A5%E8%91%B5/id6474719288"
            target={"_blank"}
            rel="noreferrer"
            id="userDownloadS5ios"
          >
            苹果手机客户端
          </a>
          <a
            style={{ alignSelf: "center" }}
            href="https://sf.yige.world/register"
            target={"_blank"}
            rel="noreferrer"
            id="userRegisterS5Web"
          >
            电脑端注册
          </a>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">项日葵文档</h3>
          <div className="pnResourceSectionMat">
            <a
              className="pnDescResource2b"
              href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfIntroduction.pdf"}
              target={"_blank"}
              rel="noreferrer"
            >
              <img className="iconMiddleCenter" src={require("../assets/document.png")} alt="项日葵概述"></img>
              <p className="secDescriptionPnFileName">概述-PPT</p>
            </a>

            <a
              className="pnDescResource2b"
              href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfIntroduction.mp4"}
              target={"_blank"}
              rel="noreferrer"
            >
              <img className="iconMiddleCenter" src={require("../assets/document.png")} alt="项日葵概述"></img>
              <p className="secDescriptionPnFileName">概述-视频</p>
            </a>

            <a
              className="pnDescResource2b"
              href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfTechnicalSpec.pdf"}
              target={"_blank"}
              rel="noreferrer"
            >
              <img className="iconMiddleCenter" src={require("../assets/document.png")} alt="技术白皮书"></img>
              <p className="secDescriptionPnFileName">技术白皮书</p>
            </a>
          </div>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <div>
            <h3 className="secTitlePn">项日葵帮助</h3>
            <p className="secDescriptionPn">
              项日葵用户可在系统内直接搜索帮助文件，项日葵会定期向用户发布更新的培训材料
            </p>
          </div>
          <img className="pnImageInSection" src={require("../assets/sfScreenHelpPc.jpg")} alt="项日葵帮助"></img>
          <p className="secDescriptionPn">如在操作上遇到困难，用户可在系统内创建“用户反馈”（客服会尽快联系您）</p>
          <img className="pnImageInSectionS" src={require("../assets/sfScreenHelpPnPn.jpg")} alt="项日葵帮助"></img>
          <div>
            <h3 className="secTitlePn">项日葵乐于与您为伴</h3>
            <p className="secDescriptionPn">
              如果用户对一些共性话题感兴趣，项日葵还可安排直播论坛，并将相关日程通过登录消息发送给用户
            </p>
          </div>
        </div>
      </div>

      <div className="pnResourceSectionQ">
        <div className="pnDescMain">
          <h3 className="secTitlePn">常见问题</h3>
          <div className="pnFaqSearch">
            <input
              className="pnFaqSearchInput"
              type={"text"}
              placeholder={foundAny ? "输入搜索词" : "未发现任何条目，请重试"}
              value={searchKey}
              onChange={(input) => setSearchKey(input.target.value)}
            ></input>
            {(!!searchKey || !foundAny) && (
              <img
                className="iconSectionQundo"
                src={require("../assets/undo.png")}
                alt="撤销"
                onClick={clearSearch}
              ></img>
            )}
            <img
              className="iconSectionQsearch"
              src={require("../assets/searchRight.png")}
              alt="搜索"
              onClick={startSearch}
            ></img>
          </div>
          <div className="pnFaqItems">
            {showList.map((el) => {
              return (
                <div key={el.q}>
                  <p className="faqQuestion">{el.q}</p>
                  <p className="faqAnswer">{el.a}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="pnWrapperColumn">
        <p className="secDescriptionPn">
          <br />
          <br />
          <br />
        </p>
      </div>
    </div>
  );
}
