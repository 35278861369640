import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";
import { faq } from "../context/Constant";

export default function SubPagePcResource(props) {
  const [searchKey, setSearchKey] = useState("");
  const [showList, setShowList] = useState(faq);
  const [foundAny, setFoundAny] = useState(true);

  const clearSearch = () => {
    setSearchKey((searchKey) => "");
    setFoundAny(true);
    setShowList(faq);
  };

  const startSearch = () => {
    const newItems = faq.filter((el) => el.q.includes(searchKey) || el.a.includes(searchKey));
    if (newItems.length > 0) {
      setShowList(newItems);
      setFoundAny(true);
    } else {
      setShowList([]);
      setSearchKey("");
      setFoundAny(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const newItems = faq.filter((el) => el.q.includes(searchKey) || el.a.includes(searchKey));
      if (newItems.length > 0) {
        setShowList(newItems);
        setFoundAny(true);
      } else {
        setShowList([]);
        setSearchKey("");
        setFoundAny(false);
      }
    }
  };

  return (
    <div className="pcBody">
      <Helmet>
        <title>新用户注册工作室即可获得90人天的免费试用额度</title>
        <meta
          name="description"
          content="移动端注册请先下载移动端APP；项日葵用户可在系统内直接搜索帮助文件；项日葵会定期向用户发布更新的培训材料；如在操作上遇到困难，用户可在系统内创建“用户反馈”（客服会尽快联系您）"
        />
        <meta name="keywords" content="项日葵,服务管理,目标管理,屹歌" />
      </Helmet>
      <div className="pcResourceSection0"></div>

      <div className="pcResourceSection1">
        <div className="secDescription5">
          <p className="secDescription5text">
            <span className="secTitle">新用户注册</span>
            工作室即可获得90人天的免费试用额度
            <br />
            <br />
          </p>

          <a href="https://sf.yige.world/register" target={"_blank"} rel="noreferrer" id="userRegisterS5">
            电脑端注册项日葵
          </a>

          <p className="secDescription5text">
            <span className="secTitle">移动端</span>注册请先下载移动端 APP
          </p>

          <div className="secDesc5center">
            <a
              href={
                "https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/apk/sf2Pro_SunflowerToProfessionals_latest.apk"
              }
              target={"_blank"}
              rel="noreferrer"
              id="userDownloadS5android"
              download
            >
              安卓用户下载
            </a>

            <a
              href="https://apps.apple.com/cn/app/%E9%A1%B9%E6%97%A5%E8%91%B5/id6474719288"
              target={"_blank"}
              rel="noreferrer"
              id="userDownloadS5ios"
            >
              苹果用户下载
            </a>
          </div>
        </div>

        <div className="pcDescResource1a">
          <div className="qrCodeDownloadAndroid">
            <img
              className="pcPicResource1a"
              src={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/qrcode/qrCodeAndroid.png"}
              alt="下载项日葵"
            ></img>
            <p className="secDescriptionS">安卓二维码</p>
          </div>
          <div className="qrCodeDownloadIos">
            <img
              className="pcPicResource1a"
              src={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/qrcode/qrCodeIOS.png"}
              alt="下载项日葵"
            ></img>
            <p className="secDescriptionS">苹果二维码</p>
          </div>
        </div>

        <img className="pcPicResource1b" src={require("../assets/networkS.jpg")} alt="项日葵网络"></img>
      </div>

      <div className="pcResourceSection2">
        <h3 className="secTitleLeft">项日葵文档</h3>
        <div className="pcResourceSectionMat">
          <a
            className="pcDescResource2b"
            href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfIntroduction.pdf"}
            target={"_blank"}
            rel="noreferrer"
          >
            <img className="pcPicResource2a" src={require("../assets/document.png")} alt="项日葵概述"></img>
            <p className="pcDirectInline">项日葵概述-PPT</p>
          </a>

          <a
            className="pcDescResource2b"
            href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfIntroduction.mp4"}
            target={"_blank"}
            rel="noreferrer"
          >
            <img className="pcPicResource2a" src={require("../assets/document.png")} alt="项日葵概述"></img>
            <p className="pcDirectInline">项日葵概述-视频</p>
          </a>

          <a
            className="pcDescResource2b"
            href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfTechnicalSpec.pdf"}
            target={"_blank"}
            rel="noreferrer"
          >
            <img className="pcPicResource2a" src={require("../assets/document.png")} alt="技术白皮书"></img>
            <p className="pcDirectInline">技术白皮书</p>
          </a>
        </div>
      </div>

      <div className="pcResourceSection3">
        <div className="pcDescResource3a">
          <h3 className="secTitle">项日葵帮助</h3>
          <p className="secDescription">
            项日葵用户可在系统内直接搜索帮助文件
            <br />
            项日葵会定期向用户发布更新的培训材料
            <br />
            如在操作上遇到困难，用户可在系统内创建“用户反馈”（客服会尽快联系您）
          </p>
        </div>

        <img className="pcPicResource3a" src={require("../assets/sfScreenHelpPn.jpg")} alt="我的网络"></img>

        <div className="pcDescResource3b">
          <h3 className="secTitleRight">项日葵乐于与您为伴</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            如果用户对一些共性话题感兴趣，项日葵还可安排直播论坛，并将相关日程通过登录消息发送给用户
          </p>
        </div>

        <img className="pcPicResource3b" src={require("../assets/sfScreenHelpPc.png")} alt="工作室清单"></img>
      </div>

      <div className="pcResourceSectionQ">
        <h3 className="secTitle">常见问题</h3>
        <div className="pcFaqSearch">
          <input
            className="pcFaqSearchInput"
            type={"text"}
            placeholder={foundAny ? "输入搜索词" : "未发现任何条目，请重试"}
            value={searchKey}
            onChange={(input) => setSearchKey(input.target.value)}
            onKeyDown={handleKeyDown}
          ></input>
          {(!!searchKey || !foundAny) && (
            <img
              className="iconSectionQundo"
              src={require("../assets/undo.png")}
              alt="撤销"
              onClick={clearSearch}
            ></img>
          )}
          <img
            className="iconSectionQsearch"
            src={require("../assets/searchRight.png")}
            alt="搜索"
            onClick={startSearch}
          ></img>
        </div>
        <div className="pcFaqItems">
          {showList.map((el) => {
            return (
              <div key={el.q}>
                <p className="faqQuestion">{el.q}</p>
                <p className="faqAnswer">{el.a}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
