import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePnMain(props) {
  return (
    <div className="pnBody">
      <Helmet>
        <title>项日葵基于专业服务理念提升业务绩效</title>
        <meta
          name="description"
          content="项日葵的功能结构为服务业务量身打造，功能所覆盖的业务范围包括：项目业务、非项目业务；适用用户包括：商务合作中的甲乙方、公司内部的职能部门员工"
        />
        <meta name="keywords" content="项日葵,服务管理,目标管理,屹歌" />
      </Helmet>
      <div className="pnWrapperColumn">
        <h3 className="secTitlePn">项日葵&reg;</h3>

        <p className="secDescriptionPn">
          供需双方的协同平台
          <br />
          专属服务团队的工作空间
        </p>

        <br />

        <button id="buttonFreeTrial" onClick={() => props.goResource()}>
          注册/下载
        </button>

        <br />

        <a
          style={{ textDecoration: "none" }}
          href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfIntroduction.mp4"}
          target={"_blank"}
          rel="noreferrer"
        >
          <button id="buttonLight">视频简介</button>
        </a>

        <img className="pnImageInSection" src={require("../assets/picMainA1.png")} alt="项日葵"></img>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            <span className="secTitleSpn">行者，歌相伴</span> 工作是一场旅程，项日葵与您为伴，支持并聆听您谱写的职业之歌
          </p>
        </div>

        <div className="pnDescMain">
          <p className="secDescriptionPn">
            <span className="secTitleSpn">舞者，师相随</span> 项日葵帮您在工作圈里找到真师诤友，并默默成为您成长的见证者
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <h3 className="secTitlePn">项日葵让服务交付更专业、更有效</h3>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            <span className="secTitleSpn">合作者，约为先</span>
            基于服务的业务对象如需求、方案或任务，项日葵总是让供需双方事先确认业务要求和期望进度，并针对变更提供版本追溯及管理功能
          </p>
        </div>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            <span className="secTitleSpn">合成者，心相齐</span>
            服务依靠团队产出，项日葵让目标-计划-反馈的闭环在每个交付环节里实现，团队成员可根据反馈结果和业务优先级实时调整资源投入
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">项日葵保障服务的稳步推进</h3>
          <p className="secSubTitleSpn">信任始于承诺、得于成果，项日葵帮助团队轻松建立信任</p>
        </div>
        <img className="pnImageInSection" src={require("../assets/picMain31.png")} alt="项日葵"></img>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            项日葵处理的业务对象是服务人士熟知的： <span className="secTitleSpn">需求、方案、任务、问题、风险</span>
            <br />
            <br />
            项日葵帮用户缕清这些对象关系，并按照用户的要求对关联对象进行预警（比如提醒用户某个需求存在潜在风险或严重问题）
            <br />
            <br />
            同时，团队可按需建立对业务对象的协同机制（事先确认各种业务要求或进度）和反馈机制（事后对交付成果做专业评价）
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">项日葵推广专业的服务理念</h3>
          <p className="secSubTitleSpn">服务以和谐为美，项日葵用专业的方式促成合作</p>
        </div>
        <img className="pnImageInSectionS" src={require("../assets/picMain32.png")} alt="项日葵"></img>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            项日葵的功能结构为服务业务量身打造，功能所覆盖的<span className="secTitleSpn">业务范围</span>包括：
            <br />
            <br />
            项目业务
            <br />
            非项目业务
            <br />
            <br />
            <span className="secTitleSpn">适用用户</span>包括：
            <br />
            <br />
            商务合作中的甲乙方
            <br />
            公司内部的职能部门员工
            <br />
            <br />
            项日葵的主要<span className="secTitleSpn">功能元素</span>有：
            <br />
            <br />
            对各业务对象的维护和更新 <br />
            针对各业务对象的供需方协同 <br />
            针对业务产出的评估和反馈 <br />
            对业务对象和关联对象的查询 <br />
            对业务数据的分析和预警 <br />
            对业务历史的版本追溯 <br />
            对服务团队的管理 <br />
            用户消息或提醒
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">项日葵团队深耕服务行业多年</h3>
        </div>
        <img className="pnImageInSectionS" src={require("../assets/picMain41.png")} alt="项日葵"></img>
        <div className="pnDescMain">
          <p className="secDescriptionPn">
            项日葵的<span className="secTitleSpn">核心团队</span>
            由经验丰富的设计师和架构师组成，相关成员曾在世界500强公司供职20年以上，所服务客户也是各行业的佼佼者。项日葵产品团队深谙服务实践和团队管理之道，也坚定地认为专业服务人士应该有适合自己的数字化系统
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">项日葵的愿景</h3>
          <p className="secSubTitleSpn">
            项日葵的愿景是帮助服务供需方构建这样一个团队：在这个团队里，所有人可以默契配合、和谐工作，也可以：
          </p>
        </div>
        <img className="pnImageInSection" src={require("../assets/picMain5.png")} alt="项日葵"></img>
        <div className="pnMainDivColumnBlue">
          <p></p>
          <h3 className="secTitleWhitePn">项日葵，专业服务领域的供需协同平台</h3>
          <p className="secDescriptionWhitePn">新用户注册即可享受90人天的免费试用额度</p>
          <button onClick={() => props.goResource()}>开始试用</button>
          <p></p>
        </div>
      </div>
    </div>
  );
}
