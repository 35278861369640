import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import MainPagePc from "./pc/MainPagePc";
import MainPagePn from "./phone/MainPagePn";
import "./App.css";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function App() {
  const helmetContext = {};
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <HelmetProvider context={helmetContext}>
      <div>
        {windowSize.innerWidth > 1200 && <MainPagePc></MainPagePc>}
        {windowSize.innerWidth <= 1200 && <MainPagePn></MainPagePn>}
      </div>
    </HelmetProvider>
  );
}

export default App;
